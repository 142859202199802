<template>
    <div class="main-background-image">
        <img src="../assets/images/about_project.png" :alt="$t('about_project.image')" >
    </div>
    <div class="container">
        <h1 tabindex="0">{{ $t('pages.personal_data') }}</h1>
        <Suspense>
            <template #default>
                <personal-data></personal-data>
            </template>
            <template #fallback>
                <personal-data-skeleton />
            </template>
        </Suspense>
    </div>
</template>

<script>
import PersonalDataSkeleton from "@/components/personalData/PersonalDataSkeleton";
import PersonalData from "@/components/personalData/PersonalData";

export default {
    name: "PersonalDataView",
    components: {
        'personal-data-skeleton': PersonalDataSkeleton,
        'personal-data': PersonalData,
    },
}
</script>

<style lang="scss" scoped>
.main-background-image {
    width: 100%;
    height: 20vmax;
    background-color: $suspenseLightGrey;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>