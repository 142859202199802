export default {
    pages: {
        mainpage: "Main page",
        news: "News",
        about_project: "About project",
        carriers: "Transporters",
        stops: "Stops",
        schedule: "Schedule",
        contact: "Contact",
        declaration: "Declaration of availability",
        privacy_policy: "Privacy policy",
        personal_data: "Personal data processing",
    },
    home: {
        main_description_title: "Transport Platform",
        main_description: "We improve the transport accessibility in Wielkopolska",
        form: {
            title_label: "Schedule search engine",
            title: "Schedule",
            stop_name_label: "Stop name",
            stop_name_placeholder_label: "Type the stop name...",
            stop_name_placeholder: "Type the stop name...",
            stop_name_tooltip_first_part: "You can check the name of the stops at",
            stop_name_tooltip_second_part: "list",
            carrier_label: "Transporter",
            transport_type_label: "Mean of transport",
            date_label: "Date",
            time_label: "Time",
            date_label_aria: "Select date",
            time_label_aria: "Select time",
            ok_label: "Ok",
            cancel_label: "Cancel",
            carrier_default_option: "All",
            transport_type_label_default_option: "All",
            magnifying_glass_icon: "Magnifying glass icon",
        },
        news: {
            more: "More news",
            more_icon: "More news",
        },
        buttons: {
            more_about_project: "More about the project",
            find_connection: "Find the connection",
        },
        header: {
            logo: {
                aria: "Logo of the transport platform - click to go to home page",
                alt: "Picture of the transport platform logo",
            },
            interreg_logo: {
                alt: "Picture of the interreg central europe logo",
            }
        }
    },
    about_project: {
        image: "Metro station image",
        info: "Informations about project Subnodes, conception presentation",
        schedule: "Schedule of activities",
        history: "History of the most important activities carried out so far",
        contact_info: "Contact info",
        publications: "Publications, conference materials, interviews, films"
    },
    carriers: {
        carriers_title: "Transporters",
        organisers_title: "Transport organizers",
        logo: "Logo",
        page_link: "Link to website",
    },
    stops: {
        title: "List of stops",
        carrier: "Transporter",
        show_on_map: "Show on map",
        select_all: "All",
        carrier_not_have_stops: "The specified carrier has no stops in the system",
        aria: {
            select_carriers: "Select a carrier to filter the stops",
            see_stops: "The stop named {name}. Click to view it on a map.",
        }
    },
    schedule: {
        form: {
            title_label: "Schedule search engine",
            stop_name_label: "Stop name",
            stop_name_placeholder: "Type the stop name...",
            stop_name_tooltip_first_part: "You can check the name of the stops at",
            stop_name_tooltip_second_part: "list",
        },
        buttons: {
            search_for_connections: "Find the connection",
        },
        date: "Date",
        hour: "Time",
        carrier: "Transporter",
        carrier_default_option: "All",
        mean_of_transport: "Mean of transport",
        mean_of_transport_default_option: "All",

        upcoming_courses: "Closest courses",
        travel_time: "Time of travel",
        no_curses: "No courses",
        show_more: "Show more",
        show_less: "Show less",
        clock_icon: "Ikona zegara",

        result: {
            route_label: 'Route',
            carrier_label: 'Carrier',
            transport_type_label: "Transport type",
            route_box_label: 'Course on {date} at {time} to {destination} stop'
        }
    },
    news: {
        page_label: "News list",
        card: {
            image: "News image",
            calendar: "Calendar icon",
        }
    },
    contact: {
        form: {
            title: "Contact form",
            name: "Name",
            email: "E-mail",
            content: "Content",
            agreement_required: "Agreement is required",
            validation_error: "Not all data has been completed",
            send_successful: "Message send successful",
            data_processing_part_one: "I agree to the processing of the",
            data_processing_part_two: "personal data",
            aria: {
                fullname: "Enter your name",
                mail: "Enter your address e-mail",
                description: "Enter message content",
                data_processing: "I agree to the processing of the personal data",
                data_processing_link: "Link to information about processing of personal data",
            }
        },
        buttons: {
            send: "Send",
        }
    },
    footer: {
        logo: {
            alt: {
                interreg_logo_subnodes: 'Logo of the interreg central europe SubNodes',
                interreg_logo_regiamobil: 'Logo of the interreg central europe RegiaMobil',
                european_funds: "European funds logo",
                pl_logo: "Logo of the Republic of Poland",
                self_government: "Logo of the Wielkopolska Region Self-Government",
                ue_logo: "Logo of the European Union",
            }
        }
    },
    cookies: {
        accept: "I accept the use of cookies",
    },
    wcag: {
        go_to_main: "Go to main content",
        resize_font: "Enable larger text",
        autocomplete: {
            list: "Select element from the list"
        }
    },
    pagination: {
        go_to: "Go to page",
    }
}
