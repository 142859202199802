import { createI18n } from "vue-i18n";
import en from './lang/en';
import pl from './lang/pl';
import storeCache from '../js/storeCache';
import {ref, watch} from 'vue';

const messages = {
    en,
    pl,
};

const avaliableLang = {
    'pl': '',
    'en' : '',
};


let lang = ref('pl');//navigator.language.split('-')[0];

const cache = new storeCache();

export function getLangConfig() {

    watch(lang,(newValue,oldValue)=>{
        return newValue;
    })

    if(!avaliableLang.hasOwnProperty(lang.value)){
        lang.value = 'pl';
    }
    if (cache.get('lang'))
        lang.value = cache.get('lang');

    return lang.value
}

export function setLangConfig(language) {
    cache.set('lang', language);
    window.i18n = i18n;
    i18n.global.locale = language;
    lang.value = language;

}

const i18n = createI18n({
    locale: getLangConfig(),
    fallbackLocale: 'pl',
    formatFallbackMessages: true,
    messages: messages,

});

export default i18n;