import { useStore } from "./store";
import {getLangConfig} from "@/i18n";
import da from "@vuepic/vue-datepicker";

const STATUS_CODE = {
    ok: 200,
    error: 500,
    emptyResponse: 991,
    emptyData: 997,

}

export default class API {
    static get STATUS_CODE() {
        return STATUS_CODE
    }

    static sendRequest({url, method="GET", data={} }, callback){
        return new Promise((resolve,reject) => {
            let myRequest = null;
            if (method == 'GET')
                myRequest = new Request(url, {method: method});
            else {
                const formData = new FormData();
                for (const property in data)
                    formData.append(property, data[property])

                myRequest = new Request(url, {method: method, body: formData});
            }

            const ctx = {
                resolve: resolve,
                reject: reject,
            }

             fetch(myRequest)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok')
                    }
                    return response.json();
                })
                .then(response => {
                    callback.bind(ctx)(response);
                });
        })
    }

    static async getPrivacyPolicy() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getPrivacyPolicy,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getCookiesUsage() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getCookiesUsage,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getPersonalDataProcessingLabel() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getPersonalDataProcessingLabel,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getPersonalDataProcessing() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getPersonalDataProcessing,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getAccessibilityDeclaration() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getAccessibilityDeclaration,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getNews(page = 1, pagination = 6, ) {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getNews,
        };
        let additionalParams = `page=${page}&pagination=${pagination}&lang=${getLangConfig()}`;

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?${additionalParams}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getNewsDetails(id) {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getNewsDetails,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?id=${id}lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getAboutProject() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getAboutProject,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getCarriers() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getCarriers,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getTransportOrganizers() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getTransportOrganizers,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getStops(id,page,pagination=10) {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getStops,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?id=${id}&lang=${getLangConfig()}&page=${page}&pagination=${pagination}`,
            methods: 'GET'
        }

        store.setLoading(true);
        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
            store.setLoading(false);
        })
    }

    static async getCarriersForSearch() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getCarriersForSearch,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}`,
            methods: 'GET'
        }
        store.setLoading(true);
        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
            store.setLoading(false);
        })
    }

    static async getFormOfTransport() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getFormOfTransport,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async sendContactForm(name,email,content) {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.sendContactForm,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}`,
            method: 'POST',
            data: {
                name: name,
                email: email,
                content: content
            }
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async searchConnection(data = {}) {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getSearchConnections,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}`,
            methods: 'GET'
        }

        if(Object.keys(data).length) {
            apiData.url += `?date=${data.date}&lang=${getLangConfig()}&limit=10&name=${data.station}&time=${data.time}`;

            if(('transporter' in data) && data['transporter'] !== 'all')
                apiData.url += `&carrier_id=${data.transporter}`;

            if(('transport_type' in data) && data['transport_type'] !== 'all')
                apiData.url += `&type_id=${data.transport_type}`;
        }


        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }

    static async getBusStation() {
        const store = useStore();
        const storeApi =  {
            backendUrl: store.api.backendUrl,
            endpoint: store.api.endpoints.getFindBusStation,
        };

        const apiData = {
            url: `${storeApi.backendUrl}${storeApi.endpoint}?lang=${getLangConfig()}&page=1&pagination=10000&text=`,
            methods: 'GET'
        }

        return await API.sendRequest(apiData,function (response) {
            this.resolve(response);
        })
    }
}
