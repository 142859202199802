<template>
    <default-header />
      <main>
        <slot />
      </main>
    <default-footer />
</template>

<style lang="scss" scoped>
</style>

<script>
import DefaultHeader from './DefaultHeader.vue';
import DefaultFooter from './DefaultFooter.vue';

export default {
  components: {
    DefaultHeader,
    DefaultFooter
  }
}
</script>
