<template>
    <div class="autocomplete">
        <div class="autocomplete-container">
            <input
                type="text"
                :id="id"
                :class="{
                        'icon ': true,
                         'bordered': bordered,
                         'bordered-mobile': borderedMobile
                    }"
                v-model="searchTerm"
                :placeholder="placeholderText"
                @keydown.enter="search"
                @click.prevent="search(false)"
                @keydown.esc="closeAutocomplete"
                autocomplete="off"
            >

            <ul v-if="showResult" :id="'autocomplete_' + id" class="" @keyup.esc="closeAutocomplete" tabindex="-1" :aria-label="$t('wcag.autocomplete.list')">
                <template v-if="searchCountries.length">
                  <li
                      v-for="country in searchCountries"
                      :key="country[itemsValueKey]"
                      @keypress.enter="selectCountry(country[itemsValueKey], country[itemsNameKey])"
                      @click="selectCountry(country[itemsValueKey], country[itemsNameKey])"
                      tabindex="0"
                  >
                    {{ country[itemsNameKey] }}
                  </li>
                </template>
              <template v-else>
                <li
                    v-for="country in items"
                    :key="country[itemsValueKey]"
                    @keypress.enter="selectCountry(country[itemsValueKey], country[itemsNameKey])"
                    @click="selectCountry(country[itemsValueKey], country[itemsNameKey])"
                    tabindex="0"
                >
                  {{ country[itemsNameKey] }}
                </li>
              </template>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.autocomplete {
    display: block;
    width: 100%;
    z-index: 1;
    color: $defaultTextColor;

    &-container {
        position: relative;

        input {
            font-size: 1.6rem;
            border-radius: 100px;
            padding: 15px 20px;
            width: 100%;
            border: none;
            outline: none;
            box-sizing: border-box;
            background-color: white;
            //position: absolute;
            text-transform: uppercase;

            &.bordered {
                border: solid 2px $defaultColor;
            }

            &.icon {
                padding-left: 25px;
                background-image: v-bind(icon);
                background-repeat: no-repeat;
                background-position: 98%;
                background-size: 20px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 10px;
            border: solid 1px #ddd;
            max-height: 300px;
            overflow-y: scroll;
            padding: 0;
            position: absolute;
            width: 100%;
            top: 40px;
            z-index: 50;

            & li {
                list-style: none;
                cursor: pointer;
                padding: 15px 10px;
                font-weight: normal;
                text-transform: uppercase;

                &:hover, &:focus {
                    background-color: #95c5ff;
                }
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, 'md-max')) {
    .autocomplete {
        &-container {
            input {
                &.bordered-mobile {
                    border: solid 2px $defaultColor;
                    border-radius: 15px;
                }
            }
        }
    }
}

</style>

<script>
import {ref, computed, onMounted, toRefs, watch} from 'vue';

export default {
    props: {
        placeholderText: {
            type: String,
            default: 'Wybierz...'
        },
        id: {
            type: String,
            default: 'search',
        },
        items: {
            type: Array,
            default: [],
        },
        itemsNameKey: {
            type: String,
            default: 'id',
        },
        itemsValueKey: {
            type: String,
            default: 'value',
        },
        icon: {
            type: String,
            default: 'http://localhost:8082/img/arrow_down.6b335479.svg',
        },
        selectedItem: {
            type: [String,Number],
            default: '',
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        borderedMobile: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, {emit}) {
        let searchTerm = ref('');
        let {items, selectedItem} = toRefs(props);

        function isNumber(n) {
            return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
        }

        watch(items,(curr,old) => {
            let selectedItemTemp = selectedItem.value;
            items.value.filter(item => {
                if(isNumber(selectedItem.value))
                    selectedItemTemp = parseInt(selectedItem.value);

                if(item[props.itemsValueKey] === selectedItemTemp) {
                    searchTerm.value = item[props.itemsNameKey];
                    selectedCountry.name = item[props.itemsNameKey];
                    selectedCountry.value = item[props.itemsNameKey];
                }
            });
        })

        const searchCountries = computed(() => {
            if (searchTerm.value === '' || searchTerm.value == selectedCountry.name)
                return items

            selectedCountry.name = '';

            let matches = 0
            const result = items.value.filter(country => {
                if ((country[props.itemsNameKey]).toLowerCase().includes(searchTerm.value.toLowerCase()) && matches < 10) {
                    matches++
                    return country
                }
            });

            emit('onInput', {search: searchTerm.value, result: result, matches: matches});
            return result;
        });

        const showResult = ref(false);

        const closeAutocomplete = () => {
            showResult.value = false;
            document.getElementById(props.id).focus();
        }

        const selectCountry = (countryValue, countryName) => {
            selectedCountry.value = countryValue
            selectedCountry.name = countryName
            //clear result if necessary
            searchTerm.value = countryName;
            showResult.value = false;
            document.getElementById('autocomplete_' + props.id).setAttribute('tabindex', '-1');
            document.getElementById(props.id).focus();

            emit('onSelect', {value: countryValue, name: countryName});
        }
        let selectedCountry = ref('');

        onMounted(() => {
            document.addEventListener('click', (e) => {
                const targetID = e.target.id;
                if (targetID != props.id)
                    showResult.value = false;

            })
        })

      const search = (withFocus = true) => {
        showResult.value = true;
        setTimeout(() => {
            document.getElementById('autocomplete_' + props.id).setAttribute('tabindex', '0');
            if (withFocus)
                document.getElementById('autocomplete_' + props.id).focus();
        }, 0);
      }

        return {
            items,
            searchTerm,
            searchCountries,
            selectCountry,
            selectedCountry,
            showResult,
            search,
            closeAutocomplete,
        }
    },

}
</script>