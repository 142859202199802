<template>
    <div class="news-list">
        <template v-for="newsDetails in newsList.news">

            <router-link :to="{ name: 'newsDetails', params: { id: newsDetails.id }}" class="news-card nav-link" :aria-label="newsDetails.date + ' ' + newsDetails.title">
                <div class="news-card-box break-word">
                    <div class="news-image-box">
                        <img class="news-image" :src="newsDetails.img" :alt="$t('news.card.image') + ' ' + newsDetails.title" />
                    </div>
                    <div class="news-item-date-box">
                        <span>{{ formatDate(newsDetails.date) }}</span>
                    </div>
                    <span class="news-item-header">{{ newsDetails.title }}</span>
                    <div class="news-item-desc">{{ sanitizeHtml(newsDetails.content).substring(0, 100) + '...' }}</div>

                </div>
            </router-link>
        </template>
    </div>

    <v-pagination
        v-if="displayPagination"
        v-model="page"
        :pages="newsList.pages"
        :range-size="1"
        active-color="#0000003b"
        @update:modelValue="paginationUpdate"
    />
</template>

<script>

import API from "../../js/API";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {ref} from "vue";
import router from "@/router";
import i18n from "@/i18n";
import moment from "moment";

const loadNewsList = async (page, cardsAmount) => {
    return (await API.getNews(page, cardsAmount));
};

export default {
    name: "NewsList",
    components: {VPagination},
    props: {
        cardsAmount: {
            type: Number,
            default: 8
        },
        displayPagination: {
            type: Boolean,
            default: true
        }
    },
    async setup(props) {
        let page = ref(parseInt(router.currentRoute.value.query.page));
        if(!page.value)
            page.value = 1;
        let newsList = ref(await loadNewsList(page.value, props.cardsAmount));

        function sanitizeHtml(htmlText) {
            let div = document.createElement("div");
            div.innerHTML = htmlText;
            return div.textContent || div.innerText || "";
        }

        const paginationUpdate = async (index) => {
            newsList.value = await loadNewsList(index, props.cardsAmount);
            router.push({ query: {page: index} }).catch(failure => console.error(failure));
            setPaginationLabels();
        }

        function setPaginationLabels() {
            let paginationElements = document.querySelectorAll('.Pagination .Page');
            if (paginationElements) {
                paginationElements.forEach(paginationElement => {
                    paginationElement.setAttribute('aria-label', i18n.global.t('pagination.go_to') + ' ' + paginationElement.innerHTML);
                });
            }
        }

        function formatDate(value) {
            if (value)
                return moment(String(value)).format('DD-MM-YYYY')
        }

        return {
            newsList,
            sanitizeHtml,
            page,
            paginationUpdate,
            setPaginationLabels,
            formatDate
        }
    },
    mounted() {
        this.setPaginationLabels();
    }
}
</script>

<style lang="scss" scoped>
    .news-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .news-card {
        text-decoration: none !important;
        color: inherit;
        margin: 20px 0;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        box-sizing: border-box;
        cursor: pointer;



        .news-card-box {
            overflow: hidden;

            .news-image-box {
                width: 100%;
                height: 200px;
                background: #fff;
                border-radius: 10px;

                .news-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    object-fit: fill;
                }
            }

            .news-item-date-box {
                display: flex;
                margin: 20px 0 0;
                color: #000;
            }

            .news-item-header {
                font-size: 2rem;
                display: block;
                margin-bottom: 20px;
                text-decoration: underline;
                color: $defaultColor;
            }
        }
    }

@media (min-width: map-get($grid-breakpoints,'lg')) {
    .news-card {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
</style>
