<template>
    <header>
        <button tabindex="0" class="skip-to-content-link" @click.prevent="skipToMainContent">{{ $t('wcag.go_to_main') }}</button>
        <cookies-usage/>
        <div class="container-fluid">
            <div class="top-header container">
                <div class="top-header-left">
                    <span tabindex="0" v-on:keyup.enter="openMenu = true" @click="openMenu = true" v-if="!openMenu"
                          class="icon-menu"></span>
                    <span tabindex="0" @click="openMenu = false" v-on:keyup.enter="openMenu = false" v-if="openMenu"
                          class="icon-menu-close"></span>
                    <router-link to="/" :aria-label="$t('home.header.logo.aria')"><img
                        src="../../assets/images/header/logo.svg" :alt="$t('home.header.logo.alt')"></router-link>
                </div>

                <div class="top-header-right">
                    <div class="language-buttons">
                        <button @click="chooseLang" class="language-button"
                                :class="{'language-button-active': getLangConfig() == 'pl'}"
                                aria-label="Przycisk zmiany języka na język polski">pl
                        </button>/<button @click="chooseLang" class="language-button"
                                :class="{'language-button-active': getLangConfig() == 'en'}"
                                aria-label="Przycisk zmiany języka na język angielski">en
                        </button>
                    </div>
                    <div @click="toogleFontSize" @keypress.enter="toogleFontSize" class="wcag wcag-fontSize" tabindex="0" :aria-label="$t('wcag.resize_font')">
                        <span>A</span>
                        <span>A</span>
                        <span>A</span>
                    </div>
                </div>

            </div>

            <nav class="menu" v-show="openMenu">
                <ul class="container" @click="openMenu = false">
                    <li class="menu-item">
                        <router-link to="/" class="menu-link nav-link">{{ $t('pages.mainpage') }}</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/aktualnosci" class="menu-link nav-link">{{ $t('pages.news') }}</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/o-projekcie" class="menu-link nav-link">{{ $t('pages.about_project') }}
                        </router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/transport" class="menu-link">{{ $t('pages.carriers') }}</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/przystanki" class="menu-link">{{ $t('pages.stops') }}</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/rozklad" class="menu-link">{{ $t('pages.schedule') }}</router-link>
                    </li>
                    <li class="menu-item">
                        <router-link to="/kontakt" class="menu-link">{{ $t('pages.contact') }}</router-link>
                    </li>
                    <li v-if="openMenu" class="menu-item">
                        <router-link to="/deklaracja-dostepnosci" class="menu-link">{{ $t('pages.declaration') }}</router-link>
                    </li>
                    <li v-if="openMenu" class="menu-item">
                        <router-link to="/polityka-prywatnosci" class="menu-link">{{ $t('pages.privacy_policy') }}</router-link>
                    </li>
                    <li class="menu-item menu-language-items">
                        <button @click="chooseLang" class="language-button"
                                :class="{'language-button-active': getLangConfig() == 'pl'}"
                                aria-label="Przycisk zmiany języka na język polski">pl
                        </button>
                        <button @click="chooseLang" class="language-button"
                                :class="{'language-button-active': getLangConfig() == 'en'}"
                                aria-label="Przycisk zmiany języka na język angielski">en
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<style lang="scss" scoped>
header {
    background-color: #fff;
}

.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: $padding-left;
    padding-right: $padding-right;
    flex-wrap: nowrap;

    &-left {
        display: flex;
        align-items: center;

        & > img {
            display: none;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .wcag {
            background: none;
            color: black;
            border: none;
            margin-right: 10px;
        }

        .wcag-contrast {
            background-image: url("../../assets/images/header/contrast.svg");
            background-repeat: no-repeat;
            width: 20px;
            height: 20px;
        }

        .wcag-fontSize {
            span {
                &:nth-child(1) {
                    font-size: 1.2rem;
                }

                &:nth-child(2) {
                    font-size: 1.4rem;
                }

                &:nth-child(3) {
                    font-size: 1.6rem;
                }
            }
        }

        .language-buttons {
            margin-right: 8px;

            .language-button {
                border: none;
                background: transparent;
                color: #000;
                font-size: 1.6rem;
                padding: 0;
            }

            .language-button-active {
                text-decoration: underline;
            }
        }
    }

    img {
        width: 150px;
        margin: 10px 0px 10px 30px;
        object-fit: contain;
        height: 60px;

    }

    .icon-menu {
        display: inline-block;
        width: 40px;
        height: 48px;
        background: url("../../assets/images/header/icon-menu.svg");
        background-repeat: no-repeat;
    }

    .icon-menu-close {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: url("../../assets/images/header/icon-menu-close.svg");
        transition: opacity 0.5s ease-in-out;
    }

    .icon-menu, .icon-menu-close {
        cursor: pointer;
    }
}

.menu {
    background-color: $defaultColor;
    padding-top: 0;
    text-align: center;

    .menu-language-items {
        display: none;
    }

    ul {
        gap: 10px;
        list-style: none;
        padding: 20px 0;

        li {
            &:not(:first-child) {
                padding-top: 20px;
            }

            list-style: none;

            a {
                text-decoration: none;
                color: white;
                font-size: 1.6rem;
            }

            .language-button {
                border: none;
                background: transparent;
                color: white;
                font-size: 1.6rem;
            }

            .language-button-active {
                text-decoration: underline;
            }

        }
    }
}

.router-link-active {
    text-decoration: underline !important;
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
    .top-header {
        flex-wrap: wrap;

        &-right {
            flex-wrap: wrap;
        }
        img:last-child {
            display: inline-block;
        }

        .icon-menu {
            display: none;
        }

        .language-buttons {
            display: none;
        }

        img {
            width: 200px;
        }
    }

    .menu {
        display: block !important;

        ul {
            display: flex;

            li {
                &:not(:first-child) {
                    padding-top: 0px;
                }

                margin: auto;
            }
        }

        .menu-language-items {
            display: block;
        }
    }
}


.skip-to-content-link:focus {
    transform: translateY(40%);
    background-color: yellow;
    color: black;
    z-index: 2;
}

.skip-to-content-link {
    background: #f1d600;
    border: none;
    text-decoration: none;
    color: black;
    border-radius: 60px;
    height: 30px;
    left: 50%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    position: absolute;
    transform: translateY(-150%);
    transition: transform 0.3s;
}

</style>

<script>
import {ref} from "vue";
import {getLangConfig, setLangConfig} from "@/i18n";
import CookiesUsage from "@/components/cookies/CookiesUsage";

export default {
    components: {CookiesUsage},
    setup() {
        const openMenu = ref(false);

        const skipToMainContent = () => {
            document.querySelector('main').setAttribute('tabindex','-1');
            document.querySelector('main').focus();
            document.querySelector('main').scrollIntoView();
        }

        const chooseLang = (e) => {
            const langCode = e.target.innerText;
            setLangConfig(langCode);
            window.location.reload();
        }

        const toogleContrast = () => {

        }

        const toogleFontSize = (withIncrement = true) => {
            let currentIndex = 0;
            const fontEnlargeClass = ['fontEnlarge-0', 'fontEnlarge-1', 'fontEnlarge-2'];

            if (localStorage.getItem('font-enlarge')) {
                currentIndex = parseInt(localStorage.getItem('font-enlarge'));
                if (withIncrement) {
                    if (fontEnlargeClass.length > currentIndex + 1) {
                        currentIndex = currentIndex + 1;
                    } else {
                        currentIndex = 0;
                    }
                }
            }

            document.querySelector('html').classList.remove(...fontEnlargeClass);
            document.querySelector('html').classList.add(`fontEnlarge-${currentIndex}`);
            localStorage.setItem('font-enlarge', currentIndex);
        }

        toogleFontSize(false);
        return {
            openMenu, getLangConfig, chooseLang, toogleContrast, toogleFontSize, skipToMainContent
        }
    }
}

</script>