<template>
    <Suspense>
        <template #default>
            <cookies-usage-content></cookies-usage-content>
        </template>
        <template #fallback>
            <div></div>
        </template>
    </Suspense>
</template>

<script>
import CookiesUsageContent from "@/components/cookies/CookiesUsageContent";

export default {
    name: "CookiesUsage",
    components: {CookiesUsageContent}
}
</script>

<style lang="scss" scoped>
</style>
