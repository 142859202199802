export default {
    pages: {
        mainpage: "Strona główna",
        news: "Aktualności",
        about_project: "O projekcie",
        carriers: "Przewoźnicy/Organizatorzy Transportu",
        stops: "Przystanki",
        schedule: "Rozkład jazdy",
        contact: "Kontakt",
        declaration: "Deklaracja dostępności",
        privacy_policy: "Polityka prywatności",
        personal_data: "Przetwarzenie danych osobowych",
    },
    home: {
        main_description_title: "Platforma Transportowa",
        main_description: "Poprawiamy dostępność komunikacyjną w Wielkopolsce",
        form: {
            title_label: "Wyszukiwarka rozkładu jazdy",
            title: "Rozkład jazdy",
            stop_name_label: "Nazwa przystanku",
            stop_name_placeholder_label: "Wpisz nazwę przystanku, z którego chcesz jechać",
            stop_name_placeholder: "Wpisz nazwę przystanku, z którego chcesz jechać...",
            stop_name_tooltip_first_part: "Nazwę przystanków możesz sprawdzić na",
            stop_name_tooltip_second_part: "liście przystanków",
            carrier_label: "Przewoźnik",
            transport_type_label: "Środek transportu",
            date_label: "Data",
            time_label: "Godzina",
            date_label_aria: "Wybierz datę",
            time_label_aria: "Wybierz godzinę",
            ok_label: "Zatwierdź",
            cancel_label: "Anuluj",
            carrier_default_option: "Wszyscy",
            transport_type_label_default_option: "Wszystkie",
            magnifying_glass_icon: "Ikona lupy",
        },
        news: {
            more: "Więcej aktualności",
            more_icon: "Więcej aktualności",
        },
        buttons: {
            more_about_project: "Więcej o projekcie",
            find_connection: "Wyszukaj połączenie",
        },
       header: {
           logo: {
               aria: "Logo platformy transportowej - kliknij aby przejść do strony głównej",
               alt: "Zdjęcie przedstawiające logo platformy transportowej"
           },
           interreg_logo: {
               alt: 'Zdjęcie przedstawiające logo interreg central europe'
           }
       }
    },
    about_project: {
        image: "Zdjęcie stacji metra",
        info: "Informacje o projekcie Subnodes, prezentacja koncepcji lub jej wybranych slajdów",
        schedule: "Harmonogram działań",
        history: "Historia najważniejszych działań zrealizowanych dotychczas",
        contact_info: "Informacje kontaktowe",
        publications: "Publikacje materiałów konferencyjnych, wywiadów oraz filmów"
    },
    carriers: {
        carriers_title: "Przewoźnicy",
        organisers_title: "Organizatorzy transportu",
        logo: "Logo",
        page_link: "Link do strony",
    },
    stops: {
        title: "Lista przystanków",
        carrier: "Przewoźnik",
        show_on_map: "Zobacz na mapie",
        select_all: "Wszyscy",
        carrier_not_have_stops: "Podany przewoźnik nie ma przystanków w systemie",
        aria: {
            select_carriers: "Wybierz przewoźnika aby przefiltrować przystanki",
            see_stops: "Przystanek o nazwie {name}. Kliknij aby zobaczyć go na mapie."
        }
    },
    schedule: {
        form: {
            title_label: "Wyszukiwarka rozkładu jazdy",
            stop_name_label: "Nazwa przystanku",
            stop_name_placeholder: "Wpisz nazwę przystanku, z którego chcesz jechać...",
            stop_name_tooltip_first_part: "Nazwę przystanków możesz sprawdzić na",
            stop_name_tooltip_second_part: "liście przystanków",
        },
        buttons: {
            search_for_connections: "szukaj połączeń",
        },
        date: "Data",
        hour: "Godzina",
        carrier: "Przewoźnik",
        carrier_default_option: "Wszyscy",
        mean_of_transport: "Środek transportu",
        mean_of_transport_default_option: "Wszystkie",

        upcoming_courses: "Najbliższe kursy",
        travel_time: "Czas przejazdu",
        no_curses: "Brak kursów",
        show_more: "Pokaż więcej",
        show_less: "Pokaż mniej",
        clock_icon: "Ikona zegara",

        result: {
            route_label: 'Trasa',
            carrier_label: 'Przewoźnik',
            transport_type_label: "Środek transportu",
            route_box_label: 'Kurs dnia {date} o {time} do przystanku {destination}'
        }
    },
    news: {
        page_label: "Lista aktualności",
        card: {
            image: "Zdjęcie aktualności",
            calendar: "Ikonka kalendarza",
        }
    },
    contact: {
        form: {
            title: "Formularz kontaktowy",
            name: "Imię i nazwisko",
            email: "E-mail",
            content: "Treść",
            agreement_required: "Zgoda jest wymagana",
            validation_error: "Nie uzupełniono wszystkich pól",
            send_successful: "Pomyślnie wysłano wiadomość",
            data_processing_part_one: "Wyrażam zgodę na przetwarzanie",
            data_processing_part_two: "danych osobowych",
            aria: {
                fullname: "Podaj imię i nazwisko",
                mail: "Podaj adres e-mail",
                description: "Podaj treść wiadomości",
                data_processing: "Wyrażam zgodę na przetwarzanie danych osobowych",
                data_processing_link: "Link do informacji o przetwarzaniu danych osobowych",
            }
        },
        buttons: {
            send: "Wyślij",
        }
    },
    footer: {
        logo: {
            alt: {
                interreg_logo_subnodes: 'Logo interreg central europe SubNodes',
                interreg_logo_regiamobil: 'Logo interreg central europe RegiaMobil',
                european_funds: "Logo funduszy europejskich",
                pl_logo: "Logo Rzeczpospolitej Polskiej",
                self_government: "Logo Samorządu Województwa Wielkopolskiego",
                ue_logo: "Logo Unii Europejskiej",
            }
        }
    },
    cookies: {
        accept: "Akceptuję wykorzystanie plików cookie",
    },
    wcag: {
        go_to_main: "Przejdź do treści głównej",
        resize_font: "Włącz większy tekst",
        autocomplete: {
            list: "Wybierz element z listy"
        }
    },
    pagination: {
        go_to: "Przejdź do strony",
    }
}
