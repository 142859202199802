<template>
    <div class="skeleton-content">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: "AvailabilityDeclarationSkeleton"
}
</script>

<style lang="scss" scoped>
@keyframes pulse-bg {
    0% { background-color: $suspenseLightGrey; }
    50% { background-color: $suspenseDarkGrey; }
    100% { background-color: $suspenseLightGrey; }
}

.skeleton-content {
    width: 100%;
    margin-bottom: 40px;

    & > div {
        width: 100%;
        height: 16px;
        margin: 10px 0;
        animation: pulse-bg 2s infinite;
    }
}
</style>