import { defineStore } from 'pinia'
import API from "@/js/API";

export const useStore = defineStore('store', {
    state: () => {
        return {
            api: {
                backendUrl: 'http://backend.platformatransportowa.com/',
                endpoints: {
                    getNews: 'api/getNews',
                    getNewsDetails: 'api/getNewsById',
                    getAboutProject: 'api/getProject',
                    getCarriers: 'api/getCarriers',
                    getTransportOrganizers: 'api/getTransportOrganizers',
                    getCarriersForSearch: 'api/getCarriersForSearch',
                    getStops: 'api/getBusStopWithId',
                    sendContactForm: 'api/sendEmail',
                    getFormOfTransport: 'api/getFormOfTransport',
                    getPrivacyPolicy: 'api/getPrivacyPolicy',
                    getCookiesUsage: 'api/getCookiesUsage',
                    getPersonalDataProcessingLabel: 'api/getPersonalDataProcessingLabel',
                    getPersonalDataProcessing: 'api/getPersonalDataProcessing',
                    getAccessibilityDeclaration: 'api/getAccessibilityDeclaration',
                    getSearchConnections: 'api/searchConnection',
                    getFindBusStation: 'api/findBusStation'
                }
            },
            loading: false,
            allNews: [],
            newsListData: [],
            aboutProject: "",
            carriersListData: [],
            organisersListData: [],
            stopsData: [],
            carriersForSearchData: [],
            allTransportTypes: [],
            privacyPolicy: "",
            cookiesUsage: "",
            personalDataProcessingLabel: "",
            personalDataProcessing: "",
            availabilityDeclaration: "",
            busStation: [],
        }
    },
    getters: {
         getPrivacyPolicy(state) {
            return this.privacyPolicy;
        },
         getCookiesUsage(state) {
            return this.cookiesUsage;
        },
         getPersonalDataProcessingLabel(state) {
            return this.personalDataProcessingLabel;
        },
         getPersonalDataProcessing(state) {
            return this.personalDataProcessing;
        },
         getAvailabilityDeclaration(state) {
            return this.availabilityDeclaration;
        },
        getCarriersListData(state) {
            return this.carriersListData;
        },
        getOrganisersListData(state) {
            return this.organisersListData;
        },
         getCarrierForSearchData(state) {
            return this.carriersForSearchData;
        },
        getAllTransportTypes(state) {
            return this.allTransportTypes;
        },
        getAboutProject(state) {
            return this.aboutProject;
        },
         getStopsData(state) {
            return this.stopsData;
        },
        getBusStation(state) {
             return this.busStation.stations
        }
    },
    actions: {
        async setPrivacyPolicy() {
            this.privacyPolicy = await API.getPrivacyPolicy();
        },
        async setCookiesUsage() {
            this.cookiesUsage = await API.getCookiesUsage();
        },
        async setPersonalDataProcessing() {
            this.personalDataProcessing = await API.getPersonalDataProcessing();
        },
        async setPersonalDataProcessingLabel() {
            this.personalDataProcessingLabel = await API.getPersonalDataProcessingLabel();
        },
        async setAvailabilityDeclaration() {
            this.availabilityDeclaration = await API.getAccessibilityDeclaration();
        },
        async setCarriersListData() {
            this.carriersListData = await API.getCarriers();
        },
        async setOrganisersListData() {
            this.organisersListData = await API.getTransportOrganizers();
        },
        async setAboutProject() {
            this.aboutProject = await API.getAboutProject();
        },
        async getNewsById(id) {
            if (id in this.allNews) {
                return this.allNews[id];
            } else {
                this.allNews[id] = await API.getNewsDetails(id);

                return this.allNews[id];
            }
        },
        async setStopsData(id,page,pagination=10) {
            this.stopsData = await API.getStops(id,page,pagination=10);
        },
        async setCarrierForSearchData() {
            let result = await API.getCarriersForSearch();

            if(!result['status'])
                this.carriersForSearchData = result;
        },
        async setAllTransportTypes() {
            this.allTransportTypes = await API.getFormOfTransport();
        },
        async setBusStation() {
            this.busStation = await API.getBusStation();
        },

        setLoading(isLoading = true) {
            this.loading = isLoading;
        }
    }
});
