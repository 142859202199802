<template>
    <div class="news-list">
        <div v-for="i in cardsAmount" :key="i" class="news-card">
            <div class="news-image-box">
            </div>
            <div class="news-item-date-box">
            </div>
            <span class="news-item-header"></span>
            <div class="news-item-desc"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: "NewsListSkeleton",
    props: {
        cardsAmount: {
            type: Number,
            default: 8
        }
    }
}
</script>

<style lang="scss" scoped>
@keyframes pulse-bg {
    0% { background-color: $suspenseLightGrey; }
    50% { background-color: $suspenseDarkGrey; }
    100% { background-color: $suspenseLightGrey; }
}

.news-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.news-card {
    margin: 20px 0;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;

    .news-image-box {
        width: 100%;
        height: 200px;
        border-radius: 10px;
        background-color: $suspenseLightGrey;
        animation: pulse-bg 2s infinite;
    }

    .news-item-date-box {
        display: flex;
        margin: 20px 0;
        height: 16px;
        background-color: $suspenseLightGrey;
        animation: pulse-bg 2s infinite;
    }

    .news-item-header {
        height: 20px;
        background-color: $suspenseLightGrey;
        animation: pulse-bg 2s infinite;
        display: block;
        margin-bottom: 20px;
    }

    .news-item-desc {
        height: 16px;
        background-color: $suspenseLightGrey;
        animation: pulse-bg 2s infinite;
    }
}

@media (min-width: map-get($grid-breakpoints,'lg')) {
    .news-card {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
</style>
