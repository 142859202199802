<template>
  <footer class="footer">
    <logo-section></logo-section>
    <div class="footer-main-section">
        <div class="container">
            <nav class="navbar">
                <router-link to="/aktualnosci" class="navbar-item menu-link">{{ $t('pages.news') }}</router-link>
                <router-link to="/o-projekcie" class="navbar-item menu-link">{{ $t('pages.about_project') }}</router-link>
                <router-link to="/transport" class="navbar-item menu-link">{{ $t('pages.carriers') }}</router-link>
                <router-link to="/przystanki" class="navbar-item menu-link">{{ $t('pages.stops') }}</router-link>
                <router-link to="/rozklad" class="navbar-item menu-link">{{ $t('pages.schedule') }}</router-link>
                <router-link to="/kontakt" class="navbar-item menu-link">{{ $t('pages.contact') }}</router-link>
                <router-link to="/deklaracja-dostepnosci" class="navbar-item menu-link">{{ $t('pages.declaration') }}</router-link>
                <router-link to="/polityka-prywatnosci" class="navbar-item menu-link">{{ $t('pages.privacy_policy') }}</router-link>
            </nav>
            <div class="section">
                <p class="copyright">© {{ new Date().getFullYear() }} Urząd Marszałkowski Województwa Wielkopolskiego</p>
                <div class="author-info">
                    <p>Aplikacja została wykonana przez Medway Sp. z o.o. oraz TDC POLSKA Sp. z o.o. na zlecenie Urzędu Marszałkowskiego Województwa Wielkopolskiego.</p>
                    <p>Przygotowanie aplikacji zostało współfinansowane przez Program INTERREG CENTRAL EUROPE ze środków Europejskiego Funduszu Rozwoju Regionalnego.</p>
                </div>
            </div>
        </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
    background-color: #fff;
    font-size: 1.6rem;

    .footer-logo-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;

        & > div {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding: 0 15px;
            box-sizing: border-box;

            &.interreg-logo {
                flex-grow: 0.75;
            }

            img {
                margin: auto;
                width: 100%;
                height: auto;
            }
        }
    }

    .footer-main-section {
        background: rgb(49, 49, 49);

        .navbar {
            padding: 20px 0;
            display: flex;
            gap: 10px;
            -webkit-box-pack: center;
            justify-content: center;
            box-sizing: border-box;
            position: relative;

            .navbar-item {
                margin: 0 auto;
                text-decoration: none;
                color: #fff;
            }

            &::after {
                bottom: 0;
                content: "";
                position: absolute;
                width: 400px;
                height: 1px;
                background: white;
            }
        }

        .section {
            color: #fff;
            display: flex;;
            flex-direction: column;
            gap: 30px;
            padding: 20px 0;
            text-align: center;

            p {
                margin: 0;
                font-weight: 200;

                &.copyright {
                    font-weight: 400;
                }
            }

            .author-info {
                display: flex;
                flex-direction: column;
                gap: 0;
            }
        }
    }

    .router-link-active {
        text-decoration: underline !important;
    }
}

@media (max-width: map-get($grid-breakpoints,'lg')) {
    footer {
        .footer-logo-section {
            & > div {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        .footer-main-section {
            background: $defaultColor;
            .navbar {
                display: none;
            }

            .section {
                flex-direction: column-reverse;

                .author-info {
                    gap: 30px;
                }
            }
        }
    }
}
</style>
<script>
import LogoSection from "@/components/LogoSection";
export default {
    components: {LogoSection}
}
</script>