<template>
    <div class="schedule">
        <div class="schedule-top">
            <div class="container">
                <h1 tabindex="0" :aria-label="$t('schedule.form.title_label')">{{ $t('pages.schedule') }}</h1>
                <p>
                    <label for="form_stops">{{ $t('schedule.form.stop_name_label') }}</label>
                </p>
                <div class="schedule-top-input">
                    <autocomplete
                        :items="store.getBusStation"
                        items-value-key="nazwa"
                        items-name-key="nazwa"
                        id="form_stops"
                        :placeholder-text="$t('schedule.form.stop_name_placeholder')"
                        @onSelect="chooseStops"
                        :selectedItem="$route.query.station"

                    />
                    <button @click="searchCourses">{{ $t('schedule.buttons.search_for_connections') }}</button>
                </div>
                <span><i class="info-icon"></i>{{ $t('schedule.form.stop_name_tooltip_first_part') }} <router-link
                    to="/przystanki"
                    :aria-label="$t('schedule.form.stop_name_tooltip_first_part') + ' ' + $t('schedule.form.stop_name_tooltip_second_part')">{{ $t('schedule.form.stop_name_tooltip_second_part') }}</router-link></span>
            </div>
        </div>

        <div class="schedule-bottom">
            <div class="container">
                <div class="column-left">

                    <div class="form-group date-input-item">
                        <label for="dp-input-date">{{ $t('schedule.date') }}</label>

                        <Datepicker
                            v-model="inputDate"
                            :selectText="$t('home.form.ok_label')"
                            :cancelText="$t('home.form.cancel_label')"
                            :locale="lang"
                            uid="date"
                            format="dd-MM-yyyy"
                            previewFormat="dd-MM-yyyy"
                            hideInputIcon
                            :enableTimePicker="false"
                            @update:modelValue="handleDate"
                            :ariaLabels="{ menu: $t('home.form.date_label_aria') }"
                        ></Datepicker>
                    </div>

                    <div class="form-group time-input-item">
                        <label for="dp-input-time">{{ $t('schedule.hour') }}</label>
                        <Datepicker v-model="inputTime"
                                    timePicker
                                    :selectText="$t('home.form.ok_label')"
                                    :cancelText="$t('home.form.cancel_label')"
                                    :locale="lang"
                                    uid="time"
                                    hideInputIcon
                                    @update:modelValue="handleTime"
                                    :ariaLabels="{ menu: $t('home.form.time_label_aria') }"
                        ></Datepicker>
                    </div>

                    <div class="form-group">
                        <label for="form_carrier">{{ $t('schedule.carrier') }}</label>
                        <autocomplete
                            :items="store.getCarrierForSearchData"
                            items-value-key="pid"
                            items-name-key="nazwa"
                            id="form_carrier"
                            :placeholder-text="$t('home.form.carrier_default_option')"
                            @onSelect="changeCarrier"
                            :selectedItem="$route.query.transporter"
                        />
                    </div>

                    <div class="form-group">
                        <label for="form_transport_type">{{ $t('schedule.mean_of_transport') }}</label>
                        <autocomplete
                            :items="store.getAllTransportTypes"
                            items-value-key="id"
                            items-name-key="nazwa"
                            id="form_transport_type"
                            :placeholder-text="$t('home.form.transport_type_label_default_option')"
                            @onSelect="changeTransportType"
                            :selectedItem="$route.query.transport_type"

                        />
                    </div>
                </div>

                <div class="column-right">
                    <h2 tabindex="0">{{ $t('schedule.upcoming_courses') }}</h2>

                    <template v-if="!store.loading">
                        <h3 v-show="results.length <= 0" class="no-courses break-word" tabindex="0">
                            {{ $t('schedule.no_curses') }}</h3>
                    </template>
                    <template v-show="store.loading" style="display: flex; justify-content: center">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </template>

                    <div v-show="results.length > 0 && index <= showMoreNumber" class="schedule-result"
                         v-for="(course,index) in results" :key="index"
                         :id="'course_' + index"
                         tabindex="0"
                         :aria-label="$t('schedule.result.route_box_label', {date: course.data, time: course.odjazd, destination: course.cel})">
                        <div class="schedule-result-date">
                            <p>{{ course.odjazd }}</p>
                            <div>
                                <img :alt="$t('schedule.clock_icon')" src="../assets/images/schedule/clock.png"/>
                                <span>{{ course.data }}</span>
                            </div>
                        </div>
                        <div class="schedule-result-route">
                            <h3>{{ course.cel }}</h3>
                            <div class="result">
                                <div class="group">
                                    <label>{{ $t('schedule.result.route_label') }}:</label>
                                    <p :id="'course_desc_' + index" class="description" tabindex="0" :aria-label="$t('schedule.result.route_label') + ': ' + course.opis">
                                        <button @click="showMore">{{ $t('schedule.show_more') }}</button>
                                        {{ course.opis }}
                                    </p>
                                </div>

                                <div class="group">
                                    <label>{{ $t('schedule.result.carrier_label') }}:</label>
                                    <p tabindex="0" :aria-label="$t('schedule.result.carrier_label') + ': ' + course.przewoznik">{{ course.przewoznik }}</p>
                                </div>

                                <div class="group">
                                    <label>{{ $t('schedule.result.transport_type_label') }}:</label>
                                    <p tabindex="0" :aria-label="$t('schedule.result.transport_type_label') + ': ' + course.typ">{{ course.typ }}</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-show="results.length > 0" class="show-more-result">
                        <button :class="{disabled: isActiveMoreResult}" @click="loadMoreResult">
                            {{ $t('schedule.show_more') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss" scoped>
@media (min-width: map-get($grid-breakpoints, 'lg')) {
    .schedule-top-input {
        display: flex !important;

        button {
            width: 20% !important;
            left: -140px !important;
            margin-top: 0 !important;
        }
    }

    .schedule-bottom {
        .container {
            flex-direction: row !important;

            .column-right {
                margin-left: 30px !important;

                .schedule-result {
                    flex-direction: row !important;
                }
            }
        }
    }
}

.schedule-top {
    width: 100%;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        background-image: url("../assets/images/schedule/landing_image.png");
        filter: brightness(0.5);
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        position: absolute;
        z-index: -3;
    }

    .container {
        h1 {
            color: white;
            margin: 0;
            padding: 40px 0 20px 0;
        }

        p {
            color: white;
            font-weight: bold;
        }

        span {
            color: white;
            font-size: 1.2rem;
            font-style: italic;
            font-weight: 300;
            margin-top: 20px;
            display: block;
            padding-bottom: 35px;
            margin-left: 30px;

            a {
                color: white;
            }

            .info-icon {
                background-image: url("../assets/images/schedule/information.png");
                width: 15px;
                height: 15px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                margin-right: 10px;
            }
        }

        .schedule-top-input {
            //display: flex;
            input {
                border-radius: 20px;
                border: 3px solid red;
                padding: 15px 20px;
                width: 85%;
            }

            button {
                background-color: #153e90;
                color: white;
                border-radius: 30px;
                border: none;
                padding: 14px 0;
                position: relative;
                font-size: 1.6rem;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                    background-color: $defaultDarkColor;
                }
            }
        }
    }
}

.schedule-bottom {
    margin-bottom: 40px;

    .container {
        display: flex;
        width: auto;
        flex-direction: column;
        margin-top: 20px;

        .column-left, .column-right {
            display: flex;
            flex-direction: column;
        }

        .column-left {
            flex: 30%;

            .form-group {
                font-weight: bold;

                label {
                    margin: 14px 0;
                    display: block;
                }
            }
        }

        .column-right {
            flex: 70%;
            margin-left: 0;

            .no-courses {
                width: 100%;
                text-align: center;
                font-size: 7rem;
                color: #333333;
            }

            .show-more-result {
                margin-top: 20px;
                display: flex;
                justify-content: center;

                button {
                    border: none;
                    background-color: $defaultColor;
                    color: white;
                    border-radius: 100px;
                    padding: 20px 30px;
                    cursor: pointer;

                    &.disabled {
                        background-color: #4d4d4d;
                        cursor: no-drop;

                        &:hover {
                            background-color: #4d4d4d !important;
                        }
                    }

                    &:hover {
                        background-color: $defaultDarkColor;
                    }
                }

            }

            .schedule-result {
                border-radius: 50px;
                background-color: white;
                padding: 20px;
                display: flex;
                flex-direction: column;
                margin-top: 30px;

                &-date {
                    flex: 20%;

                    & p {
                        font-size: 2.5rem;
                        font-weight: bold;
                    }

                    div {
                        display: flex;
                        align-items: center;

                        & span {
                            font-size: 1.3rem;
                            font-weight: 100;
                            color: black;
                        }

                        & img {
                            width: 20px;
                            margin-right: 5px;
                        }
                    }
                }

                &-route {
                    flex: 80%;

                    h3 {
                        font-size: 2.5rem;
                        text-transform: uppercase;
                    }

                    .result {
                        .group {
                            label {
                                font-size: 1.3rem;
                                font-weight: bold;
                            }

                            p {
                                font-size: 1.4rem;
                            }

                            .description {
                                height: 50px;
                                overflow-y: hidden;
                                position: relative;
                                line-height: 25px;

                                button {
                                    display: none;
                                }

                                &.active {
                                    height: 100%;
                                }
                            }

                            .expandable-description {
                                &:hover {
                                    display: block;
                                    width: 100%;
                                    background-color: rgba(0, 0, 0, 0.7);
                                    color: black;
                                    content: 'Pokaż więcej';

                                    button {
                                        display: block;
                                        outline: none;
                                        color: white;
                                        font-size: 1.6rem;
                                        background-color: transparent;
                                        position: absolute;
                                        top: 25%;
                                        left: 40%;
                                        border: none;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $defaultColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

</style>

<script>
import {onMounted, ref} from "vue";
import Autocomplete from "@/components/autocomplete";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import i18n, {getLangConfig} from "@/i18n";
import {useStore} from "@/js/store";
import API from "@/js/API";
import router from "@/router";

export default {
    setup() {
        const inputDate = ref(new Date());
        const inputTime = ref({
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });

        const store = useStore();
        store.setCarrierForSearchData();
        store.setAllTransportTypes();
        store.setBusStation();

        const lang = getLangConfig();
        let carrierId = null;
        let transportTypeId = null;
        let stopName = '';
        let results = ref([]);
        let showMoreNumber = ref(2);
        const isActiveMoreResult = ref(false);

        function showMore(e) {
            const parentElement = e.target.parentElement;
            parentElement.classList.toggle('active');

            if (parentElement.classList.contains('active'))
                e.target.innerHTML = i18n.global.t('schedule.show_less')
            else
                e.target.innerHTML = i18n.global.t('schedule.show_more')
        }

        function loadMoreResult() {
            let newElemsAmount = 3;
            let currentLastCourseBox = showMoreNumber.value;
            setTimeout(() => {
                let firstNewElem = document.getElementById('course_' + (parseInt(currentLastCourseBox) + 1));
                if (firstNewElem) {
                    firstNewElem.focus();
                    firstNewElem.scrollIntoView();
                }

                [1,2,3].forEach((i) => {
                    let elementId = 'course_desc_' + (parseInt(currentLastCourseBox) + parseInt(i));
                    checkIfOverflowing(document.getElementById(elementId));
                });
            }, 0);

            showMoreNumber.value = showMoreNumber.value + newElemsAmount;

            if (showMoreNumber.value >= results.value.length)
                isActiveMoreResult.value = true;

        }

        async function searchCourses() {
            results.value = [];
            document.querySelector('#form_stops').style.border = 'none';
            isActiveMoreResult.value = false;
            showMoreNumber.value = 2;

            if (stopName != '') {
                store.setLoading();
                const data = {
                    date: `${inputDate.value.getDate()}-${inputDate.value.getMonth() + 1}-${inputDate.value.getFullYear()}`,
                    time: `${inputTime.value.hours}%3A${inputTime.value.minutes}`,
                    station: stopName
                };

                if (carrierId >= 0 && carrierId != null)
                    data['transporter'] = carrierId;

                if(transportTypeId >= 0 && transportTypeId != null)
                    data['transport_type'] = transportTypeId;

                results.value = await API.searchConnection(data);
                router.push({query: data}).catch(failure => console.error(failure));
                store.setLoading(false);

            } else {
                document.querySelector('#form_stops').style.border = '3px solid red';
                document.querySelector('#form_stops').focus();
                document.querySelector('#form_stops').scrollIntoView();
            }
        }

        function changeTransportType(el) {
            transportTypeId = el.value;
            searchCourses();
        }

        function changeCarrier(el) {
            carrierId = el.value;
            searchCourses();
        }

        function chooseStops(value) {
            stopName = value.name;
        }

        function handleDate(data) {
            searchCourses();
        }

        function handleTime() {
            searchCourses();
        }

        function checkIfOverflowing(descriptionElement) {
            if (descriptionElement) {
                let isOverflowing = 50 < descriptionElement.scrollHeight;
                if (isOverflowing) {
                    descriptionElement.classList.add('expandable-description');
                }
            }
        }

        onMounted(async () => {
            store.setLoading();
            const data = router.currentRoute.value.query;

            if (Object.keys(data).length > 0) {
                if (data['station'] != '')
                    stopName = data['station'];

                if (data['transporter'] != '')
                    carrierId = data['transporter']

          if(data['transport_type'] != '')
              transportTypeId = data['transport_type']

                results.value = await API.searchConnection(data);
                store.setLoading(false);

            }

            setTimeout(() => {
                let descriptionElements = document.querySelectorAll('.schedule-result .description');
                if (descriptionElements) {
                    descriptionElements.forEach(descriptionElement => {
                        checkIfOverflowing(descriptionElement);
                    });
                }
            });
        })
        return {
            inputDate, inputTime, lang, store, showMore, results, loadMoreResult,
            changeTransportType,
            changeCarrier,
            chooseStops,
            searchCourses,
            handleDate,
            handleTime,
            showMoreNumber,
            isActiveMoreResult,
            checkIfOverflowing
        }
    },
    components: {
        Autocomplete, Datepicker
    }
}
</script>
