<template>
    <div class="home-banner">
        <div class="container title-section">
            <h1 class="break-word">Subnodes/RegiaMobil</h1>
            <div class="main-description-title">{{ $t('home.main_description_title') }}</div>
            <div class="main-description">{{ $t('home.main_description') }}</div>
            <router-link to="/o-projekcie" class="banner-button desktop-button">{{ $t('home.buttons.more_about_project') }}</router-link>
        </div>
        <div class="form-section">
            <div class="container">
                <div class="form-box" tabindex="0" :aria-label="$t('home.form.title_label')">
                    <h2 class="break-word">{{ $t('home.form.title') }}</h2>
                    <div>
                        <div class="input-desc">
                            <label for="form_stops">{{ $t('home.form.stop_name_label') }}</label>
                        </div>

                        <autocomplete
                            :items="store.getBusStation"
                            items-value-key="nazwa"
                            items-name-key="nazwa"
                            id="form_stops"
                            :placeholder-text="$t('home.form.stop_name_placeholder')"
                            @onSelect="changeStation"
                            :bordered-mobile="true"
                        />

                        <div class="form-info">
                            <i class="info-icon"></i>
                            <div>
                                {{ $t('home.form.stop_name_tooltip_first_part') }}&nbsp;
                                <router-link to="/przystanki" :aria-label="$t('home.form.stop_name_tooltip_first_part') + ' ' + $t('home.form.stop_name_tooltip_second_part')">{{ $t('home.form.stop_name_tooltip_second_part') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-row-item">
                            <div class="input-desc">
                                <label for="form_carrier">{{ $t('home.form.carrier_label') }}</label>
                            </div>

                            <autocomplete
                                :items="store.getCarrierForSearchData"
                                items-value-key="pid"
                                items-name-key="nazwa"
                                id="form_carrier"
                                :placeholder-text="$t('home.form.carrier_default_option')"
                                @onSelect="changeTransporter"
                                :bordered-mobile="true"
                            />
                        </div>
                        <div class="form-row-item">
                            <div class="input-desc">
                                <label for="form_transport_type">{{ $t('home.form.transport_type_label') }}</label>
                            </div>

                            <autocomplete
                                :items="store.getAllTransportTypes"
                                items-value-key="id"
                                items-name-key="nazwa"
                                id="form_transport_type"
                                :placeholder-text="$t('home.form.transport_type_label_default_option')"
                                @onSelect="changeTransportType"
                                :bordered-mobile="true"
                            />
                        </div>
                        <div class="form-row-item date-input-item">
                            <div class="input-desc">
                                <label for="dp-input-date">{{ $t('home.form.date_label') }}</label>
                            </div>

                            <Datepicker
                                v-model="inputDate"
                                :selectText="$t('home.form.ok_label')"
                                :cancelText="$t('home.form.cancel_label')"
                                :locale="lang"
                                uid="date"
                                format="dd-MM-yyyy"
                                previewFormat="dd-MM-yyyy"
                                hideInputIcon
                                :enableTimePicker="false"
                                :ariaLabels="{ menu: $t('home.form.date_label_aria') }"
                            ></Datepicker>
                        </div>
                        <div class="form-row-item time-input-item">
                            <div class="input-desc">
                                <label for="dp-input-time">{{ $t('home.form.time_label') }}</label>
                            </div>

                            <Datepicker v-model="inputTime"
                                        timePicker
                                        :selectText="$t('home.form.ok_label')"
                                        :cancelText="$t('home.form.cancel_label')"
                                        :locale="lang"
                                        uid="time"
                                        hideInputIcon
                                        :ariaLabels="{ menu: $t('home.form.time_label_aria') }"
                            ></Datepicker>
                        </div>
                    </div>
                    <div class="submit-box">
                        <button class="banner-button" @click.prevent="findConnection()">
                            <img class="search-icon" src="../assets/images/icons/search-solid.png" :alt="$t('home.form.magnifying_glass_icon')">
                            &nbsp;{{ $t('home.buttons.find_connection') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
   </div>

    <logo-section></logo-section>

    <div class="container">
        <div class="news-title-row">
            <h3 tabindex="0" :aria-label="$t('news.page_label')">{{ $t('pages.news') }}</h3>
            <div class="more-news">
                <img :alt="$t('home.news.more_icon')" src="../assets/images/icons/next_icon.png"/>
                &nbsp;
                <router-link to="/aktualnosci" class="navbar-item menu-link">{{ $t('home.news.more') }}</router-link>
            </div>
        </div>
        <Suspense>
            <template #default>
                <news-list :cards-amount="4" :display-pagination="false"></news-list>
            </template>
            <template #fallback>
                <news-list-skeleton :cards-amount="4" />
            </template>
        </Suspense>
    </div>

</template>

<script>
import NewsList from "@/components/news/NewsList";
import NewsListSkeleton from "@/components/news/NewsListSkeleton";
import Autocomplete from "@/components/autocomplete";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from "vue";
import {getLangConfig} from "@/i18n";
import {useStore} from "@/js/store";
import router from "@/router";
import LogoSection from "@/components/LogoSection";

export default {
    name: "HomeView",
    components: {
        LogoSection,
        NewsList,
        NewsListSkeleton,
        Datepicker,
        Autocomplete,
    },
    beforeCreate: function() {
        document.body.className = 'home';
    },
    beforeRouteLeave: function () {
        document.body.classList.remove('home');
    },
    setup() {
        const inputTime = ref({
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });

        const store = useStore();
        store.setCarrierForSearchData();
        store.setAllTransportTypes();
        store.setBusStation();

        let station = ref('');
        let transporter = ref('all');
        let transportType = ref('all');
        function changeStation(e) {
            station.value = e.value;
        }
        function changeTransporter(e) {
            transporter.value = e.value;
        }
        function changeTransportType(e) {
            transportType.value = e.value;
        }

        return {
            inputTime,
            inputDate: ref(new Date()),
            lang: getLangConfig(),
            store,
            changeStation,
            changeTransporter,
            changeTransportType,
            station,
            transporter,
            transportType,
        }
    },
    methods: {
        findConnection() {
            if (this.inputDate && this.inputTime && this.station) {
                let date = this.formatDate(this.inputDate);
                let time = this.inputTime.hours + '%3A' + this.inputTime.minutes;
                router.push({
                    name: 'rozklad',
                    query: {
                        date: date,
                        time: time,
                        station: this.station,
                        transport_type: this.transportType >= 0 ? this.transportType: 'all',
                        transporter: this.transporter ? this.transporter : 'all',
                    }
                }).catch(failure => console.error(failure));
            } else {
                document.querySelector('#form_stops').style.border = '3px solid red';
                document.querySelector('#form_stops').focus();
                document.querySelector('#form_stops').scrollIntoView();
            }
        },
        formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [day, month, year].join('-');
        }
    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 2.6rem;
    margin: 10px 0 30px;
    font-weight: 500;
}
h2 {
    font-size: 2.4rem;
    margin: 20px 0;
    font-weight: 400;
}
h3 {
    font-size: 2.2rem;
    margin: 10px 0;
    font-weight: 400;
}

.home-banner {
    margin: 0 8px;
    color: #fff;
    position: relative;
    background: transparent!important;
    padding: 20px 0 60px;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -3;
        top: 0;
        background-size: cover !important;
        background: url("../assets/images/home_banner.png") no-repeat;
        background-position: center;
        border-radius: 25px;
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: -2;
        background-image: linear-gradient(90deg,#000,rgba(255,0,0,0));
        border-radius: 25px;
    }

    .title-section {
        margin: 0 10px;
    }

    .form-section {
        position: relative;
        background: #fff;
        margin: 0 25px;
        border-radius: 20px;
        color: #000;

        //&::before {
        //    background-color: rgba(0,0,0,0.5);
        //    content: "";
        //    position: absolute;
        //    width: 100%;
        //    height: 100%;
        //    z-index: -1;
        //    top: 0;
        //    box-sizing: border-box;
        //}
    }

    .container {

        .main-description-title {
            font-size: 2rem;
            margin-top: 16px;
            font-weight: 500;
        }

        .main-description {
            font-size: 2rem;
            line-height: 2rem;
            margin-bottom: 16px;
        }

        .banner-button {
            border: none;
            background-color: $defaultColor;
            color: white;
            border-radius: 100px;
            width: fit-content;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            text-align: center;
            font-size: 1.6rem;
            line-height: 2.4rem;
            padding: 10px 30px;

            &:hover, &:active, &:focus {
                background-color: $defaultDarkColor;
            }
        }

        .desktop-button {
            display: none;
        }

        .form-box {
            margin-top: 40px;
            padding-top: 20px;

            input {
                width: 100%;
                border: 1px solid #bdbdbd;
                border-radius: 20px;
                height: 40px;
                padding: 0 20px;
                font-size: 1.6rem;
                -moz-appearance:none !important;
                -webkit-appearance: none !important;
                appearance: none !important;
                background-color: white;
                box-sizing: border-box;
            }
            select {
                width: 100%;
                border: 1px solid #bdbdbd;
                border-radius: 20px;
                height: 40px;
                padding: 0 20px;
                font-size: 1.6rem;
                background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
                background-position: calc(100% - 2rem) center !important;
                -moz-appearance:none !important;
                -webkit-appearance: none !important;
                appearance: none !important;
                background-color: white;
            }

            .input-desc {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin: 7px 0;
            }

            .form-info {
                display: flex;
                align-items: center;
                margin-top: 10px;
                margin-left: 5px;
                font-size: 1.3rem;
                font-weight: 300;
                font-style: italic;
                line-height: 1.95rem;
                color: $defaultDarkColor;

                a {
                    color: $defaultDarkColor;
                    text-decoration: underline;
                }

                .info-icon {
                    display: none;
                }
            }

            .form-row {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
                margin-right: -15px;
                margin-left: -15px;

                .form-row-item {
                    position: relative;
                    width: 100%;
                    padding-right: 15px;
                    padding-left: 15px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                }
            }

            .submit-box {
                padding: 20px;
                text-align: center;

                .banner-button {
                    margin: auto;
                    border-radius: 15px;
                    width: 100%;
                    padding: 20px;

                    .search-icon {
                        width: 1.6rem;
                    }
                }
            }
        }
    }
}

.news-title-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .more-news {
        display: flex;
        align-items: center;

        img {
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            transform: rotate(270deg);
            width: 1.6rem;
        }

        a {
            text-decoration: none;
            color: #000;
        }
    }
}

@media (min-width: map-get($grid-breakpoints,'sm')) {
    .form-row-item {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (min-width: map-get($grid-breakpoints,'lg')) {
    h1 {
        font-size: 7rem;
    }

    h2 {
        font-size: 4.8rem;
    }

    h3 {
        font-size: 3.4rem;
    }

    .form-row-item {
         flex: 0 0 25%;
         max-width: 25%;
    }

    .home-banner {
        margin: 0;
        padding: 20px 0;

        &::before {
            border-radius: 0;
        }

        &:after {
            border-radius: 0;
        }

        .title-section {
            margin: auto;
        }

        .form-section {
            position: relative;
            background: transparent;
            margin: 0;
            border-radius: 0;
            color: #fff;

            &::before {
                background-color: rgba(0,0,0,0.5);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                top: 0;
                box-sizing: border-box;
            }
        }

        .container {

            .main-description-title {
                font-size: 3rem;
                margin-top: 16px;
                font-weight: 600;
            }

            .main-description {
                font-size: 3rem;
                line-height: 3rem;
                margin-bottom: 16px;
            }

            .desktop-button {
                display: flex;
            }

            .form-box {

                .submit-box {
                    padding: 20px 0;

                    .banner-button {
                        border-radius: 100px;
                        width: fit-content;
                        padding: 10px 30px;
                    }
                }

                .form-info {
                    margin-top: 20px;
                    margin-left: 30px;
                    color: #ffffff;

                    a {
                        color: #ffffff;
                    }

                    .info-icon {
                        background-image: url("../assets/images/schedule/information.png");
                        width: 1.5rem;
                        height: 1.5rem;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
</style>
