<template>
    <div class="container">
        <div class="footer-logo-section">
            <div class="interreg-logo">
                <img src="../assets/images/footer/img_interreg.png" :alt="$t('footer.logo.alt.interreg_logo_subnodes')">
            </div>
            <div class="interreg-logo">
                <img src="../assets/images/footer/img_interreg_regiamobil.png" :alt="$t('footer.logo.alt.interreg_logo_regiamobil')">
            </div>
            <div>
                <img src="../assets/images/footer/samorzad-logo.png" :alt="$t('footer.logo.alt.self_government')">
            </div>
            <div>
                <img src="../assets/images/footer/ue-logo.png" :alt="$t('footer.logo.alt.ue_logo')">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LogoSection"
}
</script>

<style lang="scss" scoped>
.footer-logo-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;

    & > div {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding: 0 40px;
        box-sizing: border-box;

        &.interreg-logo {
            flex-grow: 0.75;
        }

        img {
            margin: auto;
            width: 100%;
            height: auto;
        }
    }
}

body.home {
    footer {
        .footer-logo-section {
            display: flex;
        }
    }
    main {
        .footer-logo-section {
            display: none;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, 'md-max')) {
    body.home {
        footer {
            .footer-logo-section {
                display: none;
            }
        }
        main {
            .footer-logo-section {
                display: flex;
                padding: 60px 0;
            }
        }
    }

    .footer-logo-section {
        & > div {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 15px;
        }
    }
}
</style>