<template>
    <div v-if="store.getPersonalDataProcessing.data" class="about-project-content" tabindex="0" v-html="store.getPersonalDataProcessing.data"></div>
</template>

<script>
import {useStore} from "@/js/store";

export default {
    name: "PersonalData",
    async setup() {
        const store = await useStore();
        store.setPersonalDataProcessing();

        return {
            store
        }
    }
}
</script>

<style lang="scss" scoped>
.about-project-content {
    width: 100%;
    margin-bottom: 40px;
}
</style>