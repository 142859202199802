import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactView from "@/views/ContactView";
import ScheduleView from "@/views/ScheduleView";
import AvailabilityDeclarationView from "@/views/AvailabilityDeclarationView";
import PrivacyPolicyView from "@/views/PrivacyPolicyView";
import PersonalDataView from "@/views/PersonalDataView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/aktualnosci",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  },
  {
    path: "/aktualnosci/:id",
    name: "newsDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsDetailsView.vue"),
  },
  {
    path: "/o-projekcie",
    name: "about",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/transport",
    name: "transport",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/TransportView.vue"),
  },
  {
    path: "/przystanki",
    name: "przystanki",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StopsView.vue"),
  },
  {
    path: "/rozklad",
    name: "rozklad",
    component: ScheduleView,
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: ContactView,
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/deklaracja-dostepnosci",
    name: "availabilityDeclaration",
    component: AvailabilityDeclarationView,
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/polityka-prywatnosci",
    name: "privacyPolicy",
    component: PrivacyPolicyView,
    meta: {
      layout: 'default'
    }
  },
  {
    path: "/dane-osobowe",
    name: "personalData",
    component: PersonalDataView,
    meta: {
      layout: 'default'
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
