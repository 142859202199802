<template>
    <div id="cookie_usage_box" v-if="store.getCookiesUsage.data && localStorage.getItem('accepted-cookies') !== 'true'" class="cookie-notification">
        <div class="cookies-usage-content" tabindex="0" v-html="store.getCookiesUsage.data"></div>
        <div class="button-section">
            <button class="button" @click.prevent="acceptCookies" :aria-label="$t('cookies.accept')">OK</button>
        </div>
    </div>
</template>

<script>
import {useStore} from "@/js/store";

export default {
    name: "CookiesUsageContent",
    async setup() {
        const store = await useStore();
        store.setCookiesUsage();

        const acceptCookies = () => {
            localStorage.setItem('accepted-cookies', 'true');
            document.querySelector('#cookie_usage_box').style.display = 'none';
        }

        return {
            store,
            acceptCookies,
            localStorage
        }
    }
}
</script>

<style lang="scss" scoped>
.cookie-notification {
    background-color: rgba(255,255,255,0.9);
    padding: 20px 30px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ccc;
    box-shadow: 0 2px 10px #888888;
    font-size: 1.6rem;
    width: 100vw;
    z-index: 12;

    .button-section {
        text-align: right;

        .button {
            margin-left: auto;
        }
    }
}
</style>