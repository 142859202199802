
export default class StoreCache {
    constructor(prefix) {
        this.prefix = prefix ? prefix : 'its_wagrowiec';
    }
    createFullKey(key) {
        return this.prefix + key;
    }
    get(key) {
        return JSON.parse(localStorage.getItem(this.createFullKey(key)))
    }
    set(key, value) {
        localStorage.setItem(this.createFullKey(key), JSON.stringify(value))
    }
    remove(key) {
        localStorage.removeItem(this.createFullKey(key))
    }
    clear() {
        localStorage.clear()
    }
}