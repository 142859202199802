<template>
    <div class="container">
        <h1 tabindex="0">{{ $t('contact.form.title') }}</h1>
        <div class="row">
            <div class="left-column"></div>
            <div class="right-column">
                <div id="message_form_box" class="message" :class="messageType" v-show="message">{{ message }}</div>
                <form class="contact-form">
                    <div class="form-group">
                        <label>{{ $t('contact.form.name') }}</label>
                        <input v-model="fullname" type="text" required name="fullname"
                               :placeholder="$t('contact.form.name')" :aria-label="$t('contact.form.aria.fullname')">
                    </div>
                    <div class="form-group">
                        <label>{{ $t('contact.form.email') }}</label>
                        <input v-model="email" type="email" required name="email"
                               :placeholder="$t('contact.form.email')" :aria-label="$t('contact.form.aria.mail')">
                    </div>

                    <div class="form-group">
                        <label>{{ $t('contact.form.content') }}</label>
                        <textarea v-model="description" required :placeholder="$t('contact.form.content')"
                                  :aria-label="$t('contact.form.aria.description')"></textarea>
                    </div>
                    <div class="contact-form-bottom">
                        <label>
                            <input v-model="dataProcessing" required type="checkbox"
                                   :aria-label="$t('contact.form.aria.data_processing')">
                            <span class="personal-data-label" v-html="store.getPersonalDataProcessingLabel.data"></span>
                        </label>
                        <button @click="sendContactForm" :disabled="isSended">
                            <template v-if="!isSended">{{ $t('contact.buttons.send') }}</template>
                            <template v-else>
                                <div class="lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </template>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

.container {
    .row {
        display: flex;

        .right-column {
            flex: 70%;

            form {
                .form-group {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;

                    label {
                        font-size: 1.6rem;
                        margin-bottom: 8px;
                        font-weight: bold;
                    }

                    input {
                        padding: 14px 20px;
                        border-radius: 20px;
                        border: none;
                        font-size: 1.6rem;
                    }

                    textarea {
                        height: 150px;
                        border-radius: 20px;
                        border: none;
                        padding: 14px 20px;
                        font-size: 1.6rem;
                        font-family: inherit;
                    }
                }
            }

            .contact-form-bottom {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
                flex-direction: column;

                .personal-data-label {
                    display: inline-block;
                }

                button {
                    border-radius: 100px;
                    background-color: $defaultColor;
                    color: white;
                    padding: 14px 60px;
                    border: none;
                    cursor: pointer;
                    margin-top: 10px;
                    position: relative;
                    font-size: 1.6rem;

                    &:hover {
                        background-color: $defaultDarkColor;
                    }
                }
            }
        }

        .left-column {
            flex: 30%;
            display: none;
        }
    }

}

.message {
    width: 100%;
    border-radius: 20px;
    text-align: center;
    padding: 15px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 1px 35px -12px rgb(66 68 90);
    -moz-box-shadow: 0px 1px 35px -12px rgba(66, 68, 90, 1);
    box-shadow: 0px 1px 35px -12px rgb(66 68 90);
    color: white;

    &.success {
        background-color: #37a63b;
    }

    &.danger {
        background-color: #e34a4a;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: map-get($grid-breakpoints, 'lg')) {
    .left-column {
        display: flex !important;
    }

    .contact-form-bottom {
        flex-direction: row !important;

        button {
            margin-top: 0px !important;
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
    top: -25px;
    left: -17px;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


</style>

<script>
import {ref} from "vue";
import i18n from '../i18n/index';
import API from "@/js/API";
import {useStore} from "@/js/store";

export default {
    setup() {
        const fullname = ref('');
        const email = ref('');
        const description = ref('');
        const dataProcessing = ref(false);
        const message = ref('');
        const messageType = ref('danger');
        const isSended = ref(false);

        const store = useStore();
        store.setPersonalDataProcessingLabel();

        async function sendContactForm(e) {
            e.preventDefault();
            let hasError = false;
            isSended.value = true;

            if (!dataProcessing.value || !fullname.value || !email.value || !description.value) {
                message.value = i18n.global.t('contact.form.validation_error');
                hasError = true;
                isSended.value = false;

                document.querySelector('#message_form_box').style.display = 'block';
                document.querySelector('#message_form_box').setAttribute('tabindex', '0');
                document.querySelector('#message_form_box').focus();
                document.querySelector('#message_form_box').scrollIntoView();
            }

            if (!hasError) {
                message.value = '';
                const result = await API.sendContactForm(fullname.value, email.value, description.value);

                if (result.status != 1)
                    message.value = result.message;
                else {
                    message.value = i18n.global.t('contact.form.send_successful');
                    messageType.value = 'success';
                    fullname.value = '';
                    description.value = '';
                    email.value = '';
                    dataProcessing.value = false;
                }

                document.querySelector('#message_form_box').style.display = 'block';
                document.querySelector('#message_form_box').setAttribute('tabindex', '0');
                document.querySelector('#message_form_box').focus();
                document.querySelector('#message_form_box').scrollIntoView();

                isSended.value = false;
            }
        }

        return {
            fullname, email, description, sendContactForm, dataProcessing, message, messageType, isSended, store
        }

    }
}
</script>