<template>
    <div class="main-background-image">
        <img src="../assets/images/about_project.png" :alt="$t('about_project.image')" >
    </div>
    <div class="container">
        <h1 tabindex="0">{{ $t('pages.declaration') }}</h1>
        <div id="deklaracja">
            <Suspense>
                <template #default>
                    <availability-declaration></availability-declaration>
                </template>
                <template #fallback>
                    <availability-declaration-skeleton />
                </template>
            </Suspense>
        </div>
    </div>
</template>

<script>

import AvailabilityDeclaration from "@/components/availabilityDeclaration/AvailabilityDeclaration";
import AvailabilityDeclarationSkeleton from "@/components/availabilityDeclaration/AvailabilityDeclarationSkeleton";
export default {
    name: "AvailabilityDeclarationView",
    components: {AvailabilityDeclarationSkeleton, AvailabilityDeclaration}
}
</script>

<style lang="scss" scoped>
.main-background-image {
    width: 100%;
    height: 20vmax;
    background-color: $suspenseLightGrey;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

#deklaracja {
    padding-bottom: 40px;

    a {
        text-decoration: none;
        color: #007bff;
    }
}
</style>
