<template>
    <div class="main-background-image">
        <img src="../assets/images/about_project.png" :alt="$t('about_project.image')" >
    </div>
    <div class="container">
        <h1 tabindex="0">{{ $t('pages.privacy_policy') }}</h1>
        <Suspense>
            <template #default>
                <privacy-policy></privacy-policy>
            </template>
            <template #fallback>
                <privacy-policy-skeleton />
            </template>
        </Suspense>
    </div>
</template>

<script>
import PrivacyPolicySkeleton from "@/components/privacyPolicy/PrivacyPolicySkeleton";
import PrivacyPolicy from "@/components/privacyPolicy/PrivacyPolicy";

export default {
    name: "PrivacyPolicyView",
    components: {
        'privacy-policy-skeleton': PrivacyPolicySkeleton,
        'privacy-policy': PrivacyPolicy,
    },
}
</script>

<style lang="scss" scoped>
.main-background-image {
    width: 100%;
    height: 20vmax;
    background-color: $suspenseLightGrey;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
</style>